import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import PropTypes from 'prop-types';
import Card from './Card';

const facebook = {
  href: '#',
  icon: (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
        clipRule="evenodd"
      />
    </svg>
  ),
};

export default function PhotoGallery({ gallery, title = 'Activate your Superhero', caption = 'Give your child the skill of self defense and the discipline of when to use it' }) {
  return (
    <section className="z-0 py-12 bg-gray-200 shadow-inner filter drop-shadow-md">
      <h3 className="pt-2 mb-2 text-4xl font-extrabold tracking-tight text-center sm:text-3xl lg:text-4xl filter drop-shadow-lg text-primary">
        {title}
      </h3>
      <p className="text-lg text-center md:text-xl">{caption}</p>
      <ScrollContainer vertical={false} nativeMobileScroll>
        <div className="flex px-5 my-16">
          {gallery.photos.map((photo) => (
            photo.id !== undefined ? <Card photo={photo} key={`photo-${photo.id}`} /> : ''
          ))}
        </div>
      </ScrollContainer>
      <div className="flex justify-center max-w-sm mx-auto sm:max-w-none">
        <a
          href="https://www.facebook.com/yuanyendo"
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center px-4 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary bg-opacity-90 hover:bg-opacity-100 sm:px-8"
        >
          <facebook.icon className="w-6 h-6 mr-2" aria-hidden="true" />
          See more on Facebook
        </a>
      </div>

    </section>
  );
}

PhotoGallery.propTypes = {

  // eslint-disable-next-line react/forbid-prop-types
  gallery: PropTypes.object.isRequired,
  title: PropTypes.string,
  caption: PropTypes.string,

};
PhotoGallery.defaultProps = {
  title: 'Activate your superhero',
  caption: 'Give your child the skill of self defense and the discipline of when to use it',
};
