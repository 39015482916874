import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';

export default function Reviews() {
  const { spearhand } = useStaticQuery(graphql`
  query {
    spearhand: file(relativePath: { eq: "spearhand.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    schools: allSanitySchool {
        nodes {
          name
          address {
            city
            state
            street
            zip
          }
          phone
          _id
          slug {
            current
          }
        }
    }
  }
`);
  return (
    <section className="relative md:shadow-inner ">
      <div className="py-8 bg-cover lg:pt-0 bg-crystal lg:pb-0 lg:z-10 lg:relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="relative hidden lg:-my-8 lg:block">
            <div aria-hidden="true" className="absolute inset-x-0 top-0 bg-background-light h-1/2 lg:hidden" />
            <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
              <div className="z-30 overflow-hidden rounded-lg shadow-lg aspect-w-10 aspect-h-6 sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full filter">
                <GatsbyImage
                  className="object-cover lg:h-full lg:w-full"
                  imgClassName="rounded-lg"
                  image={spearhand.childImageSharp.gatsbyImageData}
                  alt="Yuan Yen Do Spearhand"
                />
              </div>
            </div>
          </div>
          <div className="lg:mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
            <div className="max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
              <blockquote>
                <div>
                  <svg
                    className="w-12 h-12 text-white opacity-25"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="mt-6 text-lg text-white md:font-medium font-base md:text-xl lg:text-2xl">
                    Yuan Yen Do has been a game changer for my seven year old son. He has found
                    an inner confidence we didn&#x27;t know he had, and a level of focus that
                    he is learning to apply outside of class as well. Master Brown and his staff are
                    friendly, encouraging, and willing to recognize and work with each child&#x27;s
                    individual needs and strengths. Their system of teaching and testing is perfect
                    for younger kids to stay motivated and keep working hard to move forward.
                  </p>
                </div>
                <footer className="mt-6">
                  <p className="text-base font-medium text-white">Kate Fardink</p>
                  <p className="text-base text-red-100 font-small">Posted April 5th, 2019 on Facebook</p>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
