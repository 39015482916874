export default function timeConverter(input) {
  let text = '';
  if (input >= 1200) {
    text = input > 1259 ? `${(input - 1200).toString()} PM` : `${input.toString()} PM`;
  } else {
    text = `${input.toString()} AM`;
  }
  const time = `${text.slice(0, -5)}:${text.slice(-5)}`;
  return (time.charAt(0) === '0') ? time.slice(1) : time;
}
