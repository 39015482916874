import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Card({ photo }) {
  return (

    <div className="mx-5 rounded shadow-md shadow-lg w-96 max-h-96 filter shrink-0">
      <figure>
        <GatsbyImage
          className="w-full rounded-sm shadow-sm h-96 filter"
          imgClassName="object-top object-cover"
          image={photo.image.asset?.gatsbyImageData}
          alt={photo.name}
        />
      </figure>
      <figcaption className="hidden font-semibold">{photo.name}</figcaption>
    </div>
  );
}

Card.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  photo: PropTypes.any.isRequired,
};
