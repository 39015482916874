/* eslint-disable no-underscore-dangle */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import Special from '../components/Special';
import ContactUs from '../components/ContactUs';
import timeConverter from '../lib/timeConverter';
import Reviews from '../components/Reviews';
import PhotoGallery from '../components/PhotoGallery';
import SEO from '../components/SEO';

export default function SingleSchoolPage({ data: { belt }, path }) {
  const strip_name = (name) => {
    const split = name.split("Belt ")
    return split[1]
  }
  return (
    <>
      <SEO
        title={`${belt.name} Belt Classes`}
        description={`Learn more about our ${belt.name} Belt Class schedule and see pictures from classes in action.`}
      />
      <div className="z-20 bg-top shadow-inner bg-background-light filter">
        {/* Header */}
        <div className="relative bg-gray-800 pb-96 lg:pb-72 filter drop-shadow-lg">
          <div className="absolute inset-0">
            <GatsbyImage
              className="object-cover w-full h-full"
              imgClassName="object-top"
              image={belt.image.asset.gatsbyImageData}
              alt={`${belt.name} Class Schedule`}
            />
            <div className="absolute inset-0 bg-cover mix-blend-multiply bg-squares" aria-hidden="true" />
          </div>
          <div className="relative px-4 pt-8 mx-auto max-w-7xl sm:pt-32 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-extrabold tracking-tight text-center text-white md:text-left md:text-5xl lg:text-6xl filter drop-shadow-lg">
              {belt.name}
              {' '}
              Schedule
            </h1>
            {belt.name == 'Purple Belt' ? (
              <p className="pt-2 pb-4 text-xl italic font-extrabold tracking-tight text-center text-white md:p-8 md:text-left md:text-3xl lg:text-4xl filter drop-shadow-lg">
              Ages 3.5 - 5.5 years old
              </p>
            ) : (
            <p className="pt-2 pb-4 text-xl italic font-extrabold tracking-tight text-center text-white md:p-8 md:text-left md:text-3xl lg:text-4xl filter drop-shadow-lg">
              All ages (starting at 5 years old)
            </p>)}

          </div>
        </div>

        {/* Overlapping cards */}
        <section
          className="relative z-10 px-4 mx-auto -mt-96 lg:-mt-48 pb-14 max-w-7xl sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            Class Schedules
          </h2>
          <div className="grid grid-cols-1 gap-y-2 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {belt.classes.map((c) => (
              <div key={c.name} className="z-20 flex flex-col bg-white shadow-xl rounded-2xl filter drop-shadow-lg">
                <div className="relative flex-1 p-8 px-6 text-center md:px-8 ">
                  <h3 className="text-xl font-medium text-gray-900">{strip_name(c.name)}</h3>
                  <p className="mt-4 text-base text-gray-500">
                    {timeConverter(c.start)}
                    {' '}
                    -
                    {' '}
                    {timeConverter(c.end)}
                  </p>
                  <p className="mt-4 text-xs italic text-gray-400">* Classes are divided by age and skill level</p>
                </div>
              </div>
            ))}
          </div>
        </section>
        <div className="z-20 overflow-hidden text-center lg:py-16 bg-background-light lg:text-left">
          <div className="px-4 mx-auto space-y-4 max-w-7xl sm:px-6 lg:px-8">
            <div className="mx-auto text-base max-w-prose lg:max-w-none">
              <h2 className="text-base font-semibold tracking-wide uppercase text-secondary">Yuan Yen Do Self Defense</h2>
              <p className="mt-2 text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                The Focus of the
                {' '}
                {belt.name}
              </p>
            </div>
            <div className="relative z-10 mx-auto text-lg font-bold text-gray-500 lg:flex max-w-prose lg:max-w-5xl lg:mx-0 ">
              <p className="lg:pl-8">
                {belt.name}
                {' '}
                Attributes
                <span className="invisible lg:visible">:</span>
              </p>
              <ul className="flex justify-center gap-2 md:px-2 max-w-[450px] flex-wrap">
                {belt.attributes.map((attribute) => <li key={attribute.name}>{attribute.name}</li>)}
              </ul>
            </div>
            <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
              <div className="relative z-10">
                <div className="pb-16 mx-auto prose text-gray-500 lg:pb-0 prose-indigo lg:max-w-none">
                  <p>
                    {belt.description[0].children.map((description) => (description?.marks.length === 0 ? description.text : (<span className="font-bold" key={description._key}>{description.text}</span>)))}
                  </p>
                </div>
              </div>
              <div className="relative hidden mx-auto text-base lg:block max-w-prose xl:-mt-20 lg:max-w-none">
                <svg
                  className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
                </svg>
                <blockquote className="relative bg-white rounded-lg shadow-lg">
                  <div className="px-6 py-6 rounded-t-lg">

                    <div className="relative ">

                      <div className="hidden md:block md:col-span-2">
                        <GatsbyImage
                          image={belt.attributeImage.asset.gatsbyImageData}
                          alt="Tenacity"
                        />
                      </div>
                    </div>
                  </div>

                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Special path={path} />
      <PhotoGallery gallery={belt.gallery} />
      <ContactUs />
      <Reviews />
    </>
  );
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query($slug: String!) {
      belt: sanityBelt(slug: { current: { eq: $slug } }) {
          attributes {
            name
          }
          attributeImage {
            asset {
              gatsbyImageData
            }
          }
          classes {
              end
              name
              start
          }
          description {
          children {
              text
              marks
              _key
          }
        }
        id
        name
        image {
          asset {
            gatsbyImageData(
            placeholder: BLURRED
            )
          }
        }
        gallery {
          name
          id
          photos {
            caption
            id
            image {
              asset {
                gatsbyImageData( placeholder: BLURRED)
              }
            }
            name
            order
            tags {
              title
            }
          }
        }
      }
    }
`;

SingleSchoolPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};
